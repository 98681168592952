import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { DivContainer, DivColumn, DivRow } from '../atoms/containers';
import { agentQuery } from '../../redux/reducers/agents';
import { AutoGrowTextArea, TextDiv } from '../atoms/texts';
import { Spacer, SpacerFluid } from '../atoms/spacers';
import ReactTextareaAutosize from 'react-textarea-autosize';

const OverlayContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;  // Add this line
    box-shadow: 0px 0px 10px 10px #000;
`;

const OverlayContent = styled(DivColumn)`
    justify-content: start;
    background-color: ${props => props.theme.colors.background};
    border-radius: 15px;
    min-width: calc(min(900px, 100% - 30px));
    max-width: calc(min(900px, 100% - 30px));
    min-height: 60px;
    max-height: calc(min(900px, 100vh - 30px));
    border: 0.5px solid ${props => props.theme.colors.overlay.border};
    padding: 15px;
    overflow-y: auto;
`;








const QueryInput = styled(ReactTextareaAutosize)`
    resize: none;
    border: none;
    outline: none;
    min-height: 30px;
    min-width: 100%;
    max-width: 100%;
    font-size: 25px;
    font-family: Courier, Helvetica;
    background-color: transparent;
    color: ${props => props.textCol ?? "white"};
    white-space: pre-wrap;  // Respect newlines and spaces
    word-wrap: break-word;  // Allow long words to be broken
`;


const BarSwirl = styled("img")`
    min-height: 15px;
    max-height: 15px;
    width: auto;
`;


const Overlay = ({agentID, spaceID}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [response, setResponse] = useState(null);
    const queryStatus = useSelector(state => state.agents.queryLoading);
    const dispatch = useDispatch();

    const overlayContentRef = useRef(null);
    const textAreaRef = useRef(null);

    useEffect(() => {
        if (isVisible && textAreaRef.current) {
            textAreaRef.current.focus();  // Step 2: Focus on the text area
        }
    }, [isVisible]);

    const dropState = () => {
        setIsVisible(false);
        setResponse(null);
        setInputValue(null);
    }

    const handleClickOutside = (event) => {
        // console.log("CLICKED");
        if (overlayContentRef.current && !overlayContentRef.current.contains(event.target)) {
            dropState();
        }
    };



    const handleInputChange = (e) => {
        // console.log("TXT", e.target.innerText);
        setInputValue(e.target.innerText);
    };

    const handleKeyDown = (e) => {
        if (e.ctrlKey && e.code === 'KeyL') {
            e.preventDefault();
            setIsVisible(prevIsVisible => {
                if (prevIsVisible) {
                    setResponse(null);
                    setInputValue(null);
                    return false;
                } else {
                    return true;
                }
            });
        } else if (e.code === 'Escape') {
            dropState();
        }
    };
    
    
    const handleInputEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (["idle", "error"].includes(queryStatus)) {
                setResponse(null);
                dispatch(agentQuery({
                    agentID,
                    question: inputValue,
                    spaces: [spaceID],
                })).then((resultAction) => {
                    if (agentQuery.fulfilled.match(resultAction)) {
                        // console.log(`Request to GPT:\n`, resultAction.payload.request);
                        setResponse(resultAction.payload.message);
                    }
                });
            }
            // setInputValue(''); // Clear the input
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // console.log("QSTS", queryStatus);

    return (<>
        {isVisible && (
            <OverlayContainer>
                <OverlayContent ref={overlayContentRef}>
                    <DivColumn w="100%">
                        {/* <AutoGrowTextArea
                            ref={textAreaRef}
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyDown={handleInputEnter}
                            placeholder="Type your query and press Enter"
                        /> */}
                        <QueryInput
                            ref={textAreaRef}
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={handleInputEnter}
                            placeholder="Type your query and press Enter"
                        />
                        {(response || queryStatus === "pending") && <Spacer h="15px"/>}
                        {(response || queryStatus === "pending") && <Spacer h="0.5px" w="100%" col="#222"/>}
                        {(response || queryStatus === "pending") && <Spacer h="15px"/>}
                        {response && <TextDiv textCol="#666">{response}</TextDiv>}
                        {queryStatus === "pending" && <DivRow w="100%" h="30px">
                            <BarSwirl src="/barswirl.gif"/>
                            <SpacerFluid/>
                        </DivRow>}
                    </DivColumn>
                </OverlayContent>
            </OverlayContainer>
        )}
    </>);
};

export default Overlay;
