import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer, { initialState as authInitialState } from './reducers/auth';
import spacesReducer, { initialState as spacesInitialState } from './reducers/spaces';
import widgetsReducer, { initialState as widgetsInitialState } from './reducers/widgets';
import agentsReducer, { initialState as agentsInitialState } from './reducers/agents';
// import portalsReducer from './reducers/portals';
// import templatesReducer from './reducers/templates';

// Your initial default state
const initialState = {
  auth: authInitialState,
  spaces: spacesInitialState,
  widgets: widgetsInitialState,
  agents: agentsInitialState,
  // Add other slices with their initial states here
};

// Universal migration function to reset the state
const universalMigration = (state) => {
  return initialState;
};

const persistConfig = {
  key: 'root',
  version: "0.1.7",  // You can change this version number as needed
  storage,
  migrate: (state, currentVersion) => {
    if (state && state._persist && state._persist.version !== currentVersion) {
      return Promise.resolve(universalMigration(state));
    }
    return Promise.resolve(state);
  },
  blacklist: [
    "auth.loading", "auth.error",
    "agents.loading", "agents.queryLoading", "agents.error", "agents.queryError"
  ]
};

const rootReducer = combineReducers({
  auth: authReducer,
  spaces: spacesReducer,
  widgets: widgetsReducer,
  agents: agentsReducer,
  // portals: portalsReducer,
  // templates: templatesReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      // Ignore these action types
      ignoredActions: ['persist/PERSIST'],
    },
  }),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
